import React from 'react'
import styled from 'styled-components'

import Header from './header'
import Provider from './provider'
import { PricesProvider } from '../lib/prices';
import Footer from './footer'
import { PersonalCapitalFloatingAd, MaintenanceWarning } from './FloatingAds.js'

import '../styles/layout.css'
import styles from './layout.module.css'
import '../styles/styles.scss'

const Content = styled.div`
  color: ${props => props.theme.theme.text.primary};
  background-color: ${props => props.theme.theme.bg.secondary};
  min-height: calc(100vh - 80px - 167px);
  /* padding: 24px 0 0 0; */

  p, li, blockquote, h1, h2, h3, h4, h5 {

    a{
      color: ${props => props.theme.theme.text.primary};
      border-bottom: 2px solid ${props => props.theme.theme.border.secondary};
      transition: border-color 0.2s ease-in-out;
      /* border-bottom: 2px dashed solid ${props => props.theme.theme.border.secondary}; */

      &:hover {
        /* background-color: ${props => props.theme.theme.border.tertiary}; */
        border-bottom: 2px solid ${props => props.theme.theme.colors.green};
        transition: border-color 0.2s ease-in-out;
      }
    }
  }
  
  p, li, blockquote {
    font-size: 1.2rem;
  }

  blockquote{
    margin: 0;
    padding: 12px;
    background-color: ${props => props.theme.theme.bg.inset};
    border-radius: 4px;
  }
`

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle, logoText, currentPage}) => (
  <PricesProvider>
    <Provider>
      {/* <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} /> */}
      <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} currentPage={currentPage} />

      {/* <div className={styles.content}>{children}</div> */}

      <Content className={styles.content}>{children}</Content>
      {/* <MaintenanceWarning /> */}
      <Footer logoText={logoText} />

      {/* <footer className={styles.footer}>
        <div className={styles.footerWrapper}>
          <div className={styles.siteInfo}>
            &copy; {new Date().getFullYear()}, Built with <a href='https://www.sanity.io'>Sanity</a>{' '}
            &amp;
            {` `}
            <a href='https://www.gatsbyjs.org'>Gatsby</a>
          </div>
        </div>
      </footer> */}
    </Provider>
  </PricesProvider>
)

export default Layout
