import React, { useState, useEffect } from 'react'
import fetch from 'isomorphic-unfetch';

// const baseUrl = process.env.BASE_URL.replace(/\/+$/, '')

const PricesContext = React.createContext()

const PricesProvider = (props) => {
  // const [locations, setLocations] = useState([])
  // const [searchResults, setSearchResults] = useState([])
  // const [isMetric, setIsMetric] = useState(false)
  // const [user, setUser] = useState(null)
  // const [isLoading, setIsLoading] = useState(false)

  // const getUser = (userObj) => {
  //   setUser(userObj)
  // }

  // console.log(user)

  // const getWeather = async (latitude, longitude) => {
  //   const res = await fetch(`${baseUrl}/api/weather?lat=${latitude}&lon=${longitude}`)
  //   const json = await res.json()
  //   // console.log(json)
  //   return(json)
  // }

  const [prices, setPrices] = useState(0)
  const [coinGeckoPrices, setCoinGeckoPrices] = useState(0)
  const [polkadotPrice, setPolkadotPrice] = useState(0)
  const [polkadotData, setPolkadotData] = useState(0)
  const [ethereumPrice, setEthereumPrice] = useState(0)
  const [ethereumData, setEthereumData] = useState(0)
  const [algorandData, setAlgorandData] = useState(0)
  const [cardanoData, setCardanoData] = useState(0)
  const [cosmosData, setCosmosData] = useState(0)
  const [kusamaData, setKusamaData] = useState(0)
  const [solanaData, setSolanaData] = useState(0)
  const [tezosData, setTezosData] = useState(0)
  const [kavaData, setKavaData] = useState(0)
  const [flowData, setFlowData] = useState(0)
  const [terraData, setTerraData] = useState(0)
  const [USTData, setUSTData] = useState(0)
  const [avaxData, setAvaxData] = useState(0)

  console.log(prices)

  const getPrices = async () => {
    const res = await fetch(`https://api.nomics.com/v1/currencies/ticker?key=${process.env.NOMICS_API_KEY}&ids=BTC,ETH,LTC,USDC,GUSD,PAX,PAXG,USDT`)
    const json = await res.json()
    setPrices(() => json)
    return(json)
  }

  const testFunc = () => {
    return ("hello world")
  }

  const getCoinGeckoPrices = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,litecoin,usd-coin,gemini-dollar,paxos-standard,pax-gold,tether,chainlink,binance-usd,dai,uniswap,basic-attention-token&vs_currencies=usd&include_last_updated_at=true`)
    const json = await res.json()
    setCoinGeckoPrices(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getPolkadotPrice = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=polkadot&vs_currencies=usd&include_last_updated_at=true`)
    const json = await res.json()
    setPolkadotPrice(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getEthereumPrice = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd&include_last_updated_at=true`)
    const json = await res.json()
    setEthereumPrice(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getEthereumData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/ethereum`)
    const json = await res.json()
    setEthereumData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getPolkadotData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/polkadot`)
    const json = await res.json()
    setPolkadotData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getAlgorandData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/algorand`)
    const json = await res.json()
    setAlgorandData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getCardanoData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/cardano`)
    const json = await res.json()
    setCardanoData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getCosmosData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/cosmos`)
    const json = await res.json()
    setCosmosData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getKusamaData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/kusama`)
    const json = await res.json()
    setKusamaData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getSolanaData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/solana`)
    const json = await res.json()
    setSolanaData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getTezosData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/tezos`)
    const json = await res.json()
    setTezosData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getKavaData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/kava`)
    const json = await res.json()
    setKavaData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getFlowData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/flow`)
    const json = await res.json()
    setFlowData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getTerraData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/terra-luna`)
    const json = await res.json()
    setTerraData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getUSTData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/terrausd`)
    const json = await res.json()
    setUSTData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  const getAvaxData = async () => {
    const res = await fetch(`https://api.coingecko.com/api/v3/coins/avalanche-2`)
    const json = await res.json()
    setAvaxData(() => Object.entries(json))
    const jsonArray = Object.entries(json)
    console.log(Object.entries(json))
    return(jsonArray)
  }

  // useEffect( () => {
  //   getCoinGeckoPrices()
  //   getPolkadotPrice()
  //   getPolkadotData()
  //   getEthereumPrice()
  //   getEthereumData()
  //   getAlgorandData()
  //   getCardanoData()
  //   getCosmosData()
  //   getKusamaData()
  //   getSolanaData()
  //   getTezosData()
  //   getKavaData()
  //   getFlowData()
  //   getTerraData()
  //   getUSTData()
  //   getAvaxData()
  // }, [])

  return(
    <PricesContext.Provider value={{
      coinGeckoPrices,
      testFunc,
      polkadotPrice,
      polkadotData,
      ethereumPrice,
      ethereumData,
      algorandData,
      cardanoData,
      cosmosData,
      kusamaData,
      solanaData,
      tezosData,
      kavaData,
      flowData,
      terraData,
      USTData,
      avaxData
    }} >
      {props.children}
    </PricesContext.Provider>
  )
}

const PricesConsumer = PricesContext.Consumer
export { PricesProvider, PricesConsumer, PricesContext }
