import React from 'react'
import styled from 'styled-components'

export const ContainerFullWidth = styled.div`
  width: 100%;
`

export const ContainerBodyWidth = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.body};
  margin: 0 auto;
`

export const ContainerMain = styled(ContainerBodyWidth)`
  padding: 24px;
  box-sizing: border-box;
`

export const ContentTwoCol = styled.div`

`
export const Backdrop = styled(ContainerFullWidth)`
  background-color: ${props => props.theme.theme.colors.white};
  border-top: 1px solid ${ props => props.theme.theme.border.secondary};
  box-sizing: border-box;
`

export const RelatedPagesLink = styled.a`
  a{
  color: ${props => props.theme.theme.colors.blue};
  border-bottom: 2px solid ${props => props.theme.theme.border.secondary};

    &:hover {
      border-bottom: 2px solid ${props => props.theme.theme.colors.blue};
    }
  }
`

export const RelatedPagesPanel = styled(ContainerMain)`
  padding: 12px 24px;
  /* padding: 0px; */
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};
  /* border: 1px solid ${(props) => props.theme.theme.colors.blue}; */
  /* background-color: ${(props) => props.theme.theme.bg.primary}; */
  background-color: ${(props) => props.theme.theme.colors.transparentBlue};
  /* box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  /* display: flex; */

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }

  p {
    /* color: ${props => props.theme.theme.colors.blue}; */

    a{
      color: ${props => props.theme.theme.colors.blue};
      border-bottom: 2px solid ${props => props.theme.theme.border.secondary};

      &:hover {
        border-bottom: 2px solid ${props => props.theme.theme.colors.blue};
      }
    }
  }
`

export const RelatedPagesGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  a{
    color: ${props => props.theme.theme.text.primary};
    border-bottom: 2px solid ${props => props.theme.theme.border.tertiary};
    transition: border-color 0.2s ease-in-out;
    padding: 4px 8px;
    background-color: ${props => props.theme.theme.colors.transparentBlue};
    /* border-bottom: 2px solid ${props => props.theme.theme.colors.green}; */
    margin: 0 20px 4px 0;

    &:hover {
      /* background-color: ${props => props.theme.theme.border.tertiary}; */
      border-bottom: 2px solid ${props => props.theme.theme.colors.blue};
      transition: border-color 0.2s ease-in-out;
    }
  }
`

export const PanelLabel = styled.h3`
  color: ${props => props.theme.theme.colors.blue};
  margin: 0px 0 8px 0;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

export const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding: 0;
    /* padding-right: 24px; */
  }
`

export const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding: 24px;
  }

`
