const light = {
  bg: {
    primary: '#fff ',
    // secondary: '#ffffff',
    secondary: '#FAFBFD',
    tertiary: '#F8F8F8',
    inset: '#e2e4e8',
    input: 'rgba(65,67,78,0.12)',
    green: 'hsl(78, 100%, 80%)'
    // green: 'hsl(78, 95%, 50%)'

  },
  text: {
    primary: '#050505',
    secondary: '#2f3037',
    tertiary: '#525560',
    quarternary: '#9194a1',
    placeholder: 'rgba(82,85,96,0.5)',
    onPrimary: '#ffffff'
  },
  border: {
    primary: '#eff0f5',
    secondary: '#DDDDDD',
    tertiary: '#C4C4C4',
  },
  formBorder: {
    primary: '#ddd'
  },
  formFill: {
    bgReadOnly: "#FAFBFD"
  },
  buttonFill: {
    secondary: "#EFEFEF"
  }
  // ...
}

const dark = {
  bg: {
    primary: '#14181B',
    secondary: '#232A2F',
    tertiary: '#181818',
    inset: '#111111',
    input: 'rgba(191,193,201,0.12)',
    green: 'hsl(117, 60%, 49%)'
  },
  text: {
    primary: '#fbfbfc',
    secondary: '#e3e4e8',
    tertiary: '#a9abb6',
    quarternary: '#6c6f7e',
    placeholder: 'rgba(145,148,161,0.5)',
    onPrimary: '#050505'
  },
  border: {
    primary: '#282828',
    secondary: '#525560',
    tertiary: '#525560'
  },
  formBorder: {
    primary: '#AEAEAE'
  },
  formFill: {
    bgReadOnly: "#232A2F"
  },
  buttonFill: {
    secondary: "#EFEFEF"
  }
  // ...
}

const defaultTheme = {
  fontSizes: [
    '14px', // 0
    '16px', // 1
    '18px', // 2
    '22px', // 3
    '26px', // 4
    '32px', // 5
    '40px'  // 6
  ],
  fontWeights: {
    body: 400,
    subheading: 500,
    link: 600,
    bold: 700,
    heading: 800,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.3,
    code: 1.6,
  },
  contentWidths: {
    body: '1200px',
    content: '700px'
  },
  colors: {
    spotifyGreen: '#1DB954',
    blue: '#2196F3',
    transparentBlue: 'rgba(33,150,243, 0.15)',
    lightBlue: '#DCEDFC',
    red: '#E90C35',
    transparentRed: 'rgba(233,12,53, 0.05)',
    green: '#3AC732',
    transparentGreen: 'rgba(58,199,50, 0.15)',
    transparentGreen2: 'rgba(58,199,50, 0.1)',
    transparentGreenHover: 'rgba(58,199,50, 0.25)',
    greenButtonText: 'rgba(229,250,230,1)',
    orange: '#FF9800',
    transparentOrange: 'rgba(255, 152, 0, 0.15)',
    purple: '#9C27B0',
    transparentPurple: 'rgba(156, 39, 176, 0.15)',
    footerBg: '#14181B',
    footerBorder: '#2E363E',
    white: '#fff',
    black: "#000",
    lightBrown: '#D4AC89',
    whiteTransparent: 'rgba(255,255,255,0.75)',
    bgReadOnly: "#FAFBFD",
    borderReadOnly: "#DDDDDD",
    pcBlue: '#04CFFF',
    pcBlueLight: '#D5F7FF',
    pcBlueTransparent: 'rgba(4,207,255, 0.2)',
    mintMobileGreen: "#69AF85",
    mintMobileOrange: "#F15D2E",
    mintMobileGreenLight: "#E1F0F1",
    mintMobileGreenTransparent: "rgba(105,175, 33, 0.2)",
    text: {
      primary: '#050505',
      secondary: '#2f3037',
      tertiary: '#525560',
      quarternary: '#9194a1',
      placeholder: 'rgba(82,85,96,0.5)',
      onPrimary: '#ffffff'
    }
  },
  buttons: {
    blackTransparent: 'rgba(0,0,0,0.1)',
    whiteTransparent: 'rgba(255,255,255,0.1)'
  }
  // ...
};

export const lightTheme = { ...defaultTheme, ...light }
export const darkTheme = { ...defaultTheme, ...dark }
